<template
  src="@/../../../templates/brand/components/templates/shop/checkout/Checkout.html"
>
</template>

<script>
// imports
import _ from 'lodash/fp'
import { v4 as uuid } from 'uuid'
import { mapActions, mapState, mapGetters } from 'vuex'
import accounting from 'accounting'
import { isOrderValid } from '@/util/util'
// import mainNav from '../../MainNav.vue';

// logic
export default {
  name: 'Checkout',
  async created() {
    if (!this.locations || !this.locations.length)
      await this.getLocations(this.user._id)
    // if (!this.collections || !this.collections.length) {
    //   await new Promise(resolve => {
    //     let unwatch = this.$watch('collections', () => {
    //       unwatch()
    //       return resolve(true)
    //     })
    //   })
    // }
    // guard against incomplete shipping address
    if (!this.user.profile.billing) {
      this.$vex.dialog.confirm({
        message:
          'To checkout you need to have a complete billing Address.  You will now be redirected to your My Account, Customer Information page so you can add/update your Shipping and Billing addresses. Once updated, you can then return here to checkout.  For further information, please see "set up your shipping and billing addresses" in the user guide.',
        callback: () => {
          this.$router.push('/myaccount/profile')
        }
      })
    } else {
      const _cart =
        _.get(this.$route.query.cartid, this.user.profile) ||
        _.get(this.$route.query.cartid, this.user.profile.shoppingcarts) ||
        {}
      console.info(' :: checkout created', _cart)
      if (!_.keys(_cart.items).length) this.$router.push('/')
      const _type = _.values(_cart.details)[0].type
      const _url = _.values(_cart.details)[0].url

      if (this.$route.query.cartid === 'cart') {
        this.setCollection(
          this.collections.find(c => c.settings.cart === 'default').url
        )
      } else if (this.$route.query.cartid === 'customorders') {
        this.setCollection(
          this.collections.find(c => c.settings.allowCustom).url
        )
      } else if (_type === 'collection') {
        this.setCollection(_url)
      } else {
        await this.getProgram({ id: _url, key: 'url' })
        this.isProgram = true
      }
      if (this.$route.name === 'reserve') {
        if (
          !_.values(_cart.details).some(
            d => d.url === this.$route.params.program
          )
        )
          this.$router.push('/')

        this.checkout = 'reserve'
        this.program
          ? await this.getPreorderProducts(this.$route.query.cartid)
          : await this.getPreorderProducts()
        this.paymentMethods = this.program.paymentMethods
      } else if (this.$route.name === 'customCheckout') {
        this.checkout = 'custom'
        // this.cart = _cart
        if (this.$route.query.cartid === 'custom') {
          await this.getCustomorderProducts(this.$route.query.cartid).then(
            res => {
              if (res.success && res.diff) {
                this.orphanedProducts = res.diff
                this.checkUser()
              }
              return res
            }
          )
        } else {
          await this.getCartProducts(this.$route.query.cartid).then(res => {
            if (res.success && res.diff) {
              this.orphanedProducts = res.diff
              this.checkUser()
            }
            return res
          })
        }
      } else {
        // this.cart = _cart
        this.checkout = 'instock'
        let p = await this.getCartProducts(this.$route.query.cartid).then(
          res => {
            if (res.success && res.diff) {
              this.orphanedProducts = res.diff
              this.checkUser()
            }
            return res
          }
        )
        if (!p.success) {
          // does it matter if p[0].qty is positive?
          this.$router.replace('/')
          return
        } else if (
          !(this.isProgram
            ? this.program.settings.ignoreInventory
            : this.collection.settings.ignoreInventory) &&
          p.data.some(item => item.qty > item.inventory)
        ) {
          let _d = p.data.find(item => item.qty > item.inventory)
          // NB: may be more than one section, may not be url of cart: TNT ONLY
          this.$router.push(`/cart/${_d.section[0].url}`)
          return
        }
      }

      if (!this.cartProducts.length) {
        // console.info(' ::::::::::::::: waiting for cartProducts')
        let unwatch = this.$watch('cartProducts', async () => {
          await this.setOrder()
          this.calcTax()
          this.loaded = true
          unwatch()
        })
      } else {
        // console.info(' ::::::::::::::: just go ahead and setOrder')
        await this.setOrder()
        this.calcTax()
        this.loaded = true
      }
    }
  },
  mounted() {
    if (_.isEmpty(this.paymentMethods))
      this.paymentMethods = _.assign(
        {},
        this.appconfig.siteConfig.paymentMethods
      )
  },
  data() {
    return {
      loaded: false,
      fixOrderElements: {},
      orphanedProducts: [],
      checkout: true,
      isProgram: false,
      paymentMethods: {}
    }
  },
  computed: {
    ...mapState({
      cartProds: ({ Cart }) => Cart.cart,
      preorderProds: ({ Product }) => Product.preorderProducts,
      customorderProds: ({ Product }) => Product.customorderProducts,
      user: ({ App }) => App.user,
      order: ({ Order }) => Order.order,
      programs: ({ Collection }) => Collection.programs,
      program: ({ Collection }) => Collection.program,
      collection: ({ Collection }) => Collection.collection,
      collections: ({ Collection }) => Collection.collections,
      locations: ({ Location }) => Location.locations
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    cart() {
      if (!this.user || !this.user.profile || _.isEmpty(this.user.profile))
        return {}
      const _cart =
        _.get(this.$route.query.cartid, this.user.profile) ||
        _.get(this.$route.query.cartid, this.user.profile.shoppingcarts)

      for (const k in _cart.items) {
        if (!_cart.items[k]) {
          delete _cart.items[k]
          delete _cart.details[k]
        }
      }
      return _cart
    },
    cartlink() {
      const collection = this.isProgram ? this.program : this.collection
      // this is only for honda...
      return collection &&
        collection.settings &&
        collection.settings.checkout &&
        !(this.isProgram && collection.settings.communityVolume)
        ? collection.url
        : false
    },
    shippingLocations() {
      if (
        (this.isProgram && !this.program.settings.multilocation) ||
        !this.collection.settings.multilocation
      )
        return false
      const _itemlocs = _.reduce(
        (acc, i) => {
          let _l = _.keys(i.locations).filter(id => i.locations[id])
          acc = _.uniq(acc.concat(_l))
          return acc
        },
        [],
        _.values(this.cart.details)
      )

      return _itemlocs.map(l => this.locations.find(loc => loc._id === l))
    },
    displaySubtotal() {
      return accounting.toFixed(this.subtotal, 2)
    },
    displayTotal() {
      if (!this.order || _.isEmpty(this.order) || !this.order.shippingService)
        return 0
      return this.order.shippingService &&
        this.order.shippingService.shippingTBD
        ? accounting.toFixed(
            this.subtotal + this.order.shippingService.rate + this.taxes,
            2
          )
        : accounting.toFixed(this.subtotal + this.shippingPrice + this.taxes, 2)
    },
    displayCredit() {
      return accounting.toFixed(this.credit, 2)
    },
    displayTotalMinusCredit() {
      return this.order &&
        this.order.shippingService &&
        this.order.shippingService.shippingTBD
        ? accounting.toFixed(
            this.subtotal +
              this.order.shippingService.rate +
              this.taxes -
              this.credit,
            2
          )
        : accounting.toFixed(
            this.taxes + this.shippingPrice + this.subtotal - this.credit,
            2
          )
    },
    products() {
      if (this.checkout === 'instock') {
        return _.isArray(this.cartProds)
          ? this.cartProds
          : _.values(this.cartProds)
      } else if (this.checkout === 'custom') {
        if (this.$route.query.cartid === 'customorders') {
          return _.isArray(this.customorderProds)
            ? this.customorderProds
            : _.values(this.customorderProds)
        } else {
          return _.isArray(this.cartProds)
            ? this.cartProds
            : _.values(this.cartProds)
        }
      } else {
        const _prods = _.isArray(this.preorderProds)
          ? this.preorderProds
          : _.values(this.preorderProds)
        return _prods.map(i => {
          if (i.customizable) {
            i.customUnitPrice =
              typeof i.customUnitPrice === 'number'
                ? i.customUnitPrice
                : this.program.customUnitPrice
          }
          return i
        })
      }
    },
    subtotal() {
      // if (!this.cartProducts || !this.cartProducts.length) return 0;
      // let _stats = this.user.profile.cart;
      // if (this.checkout === 'reserve') _stats = this.user.profile.preorders;
      // if (this.checkout === 'custom') _stats = this.user.profile.customorders;
      return _.reduce(
        (sum, i) => {
          let _adj = this.adjustedPrice(i)
          sum += _adj.subtotal
          if (this.checkout === 'custom') {
            sum += this.adjustedCustomPrice(i).subtotal
          } //else if (this.checkout === 'reserve') {
          //   sum += (i.customizable && _.get('customize', _stats.details[i.SKU])
          //     ? (i.customUnitPrice || this.program.customUnitPrice || appconfig.siteConfig.customizeItemDefaultValue) * i.volume
          //     : 0);
          // }
          return sum
        },
        0,
        this.cartProducts
      )
    },
    credit() {
      if (!this.order) return 0
      return this.order.price.credit
    },
    shippingPrice() {
      if (!this.order) return 0
      return this.order.price.shipping
    },
    taxes() {
      if (!this.order) return 0
      return _.sum(_.values(this.order.price.taxes))
    },
    programUrl() {
      if (this.checkout !== 'reserve') return ''
      return this.$route.path.slice(0, this.$route.path.indexOf('/reserve'))
    },
    cartProducts() {
      if (!this.products || !this.products.length) return []
      let _ret
      if (
        !this.cart ||
        !this.cart.items ||
        !this.products ||
        _.isEmpty(this.products)
      )
        _ret = null

      if (this.checkout === 'instock') {
        _ret = _.each(i => {
          i.qty = this.cart.items[i.SKU]
        }, this.products).filter(i => i.qty)
      } else if (this.checkout === 'custom') {
        // if (!this.customorderProds.length) return [];
        const _families = {}
        _.each(i => {
          let _sku = i.familyKey
          _sku += i.variationField.key || ''
          if (!_families[_sku]) {
            _families[_sku] = i
            _families[_sku].units = [
              { key: i.unitField.key, qty: this.cart.items[i.SKU] }
            ]
            _families[_sku].qty = this.cart.items[i.SKU]
          } else {
            _families[_sku].units.push({
              key: i.unitField.key,
              qty: this.cart.items[i.SKU]
            })
            _families[_sku].qty += this.cart.items[i.SKU]
          }
        }, this.products)
        _ret = _.map(i => _families[i], _.keys(_families))
      } else if (this.checkout === 'reserve') {
        // if (!this.preorderProds.length) return [];
        const _families = {}
        _.each(i => {
          let _sku = i.familyKey
          _sku += i.variationField.key || ''
          if (!_families[_sku]) {
            _families[_sku] = i
            _families[_sku].units = [
              { key: i.unitField.key, qty: this.cart.items[i.SKU] }
            ]
            _families[_sku].qty = this.cart.items[i.SKU]
          } else {
            _families[_sku].units.push({
              key: i.unitField.key,
              qty: this.cart.items[i.SKU]
            })
            _families[_sku].qty += this.cart.items[i.SKU]
          }
        }, this.products)
        _.each(i => {
          i.volume = _.sum(_.map(p => p.qty, i.units))
        }, _families)
        _ret = _.map(i => _families[i], _.keys(_families))
      }
      return _.sortBy(['SKU'], _ret)
    },
    orderValid() {
      if (!this.order) return false
      // is the order complete?
      // if (!this.checkout !== 'instock') return [];
      const _ret = isOrderValid(this.order)
      if (_ret.missing.length || _ret.invalid.length) {
        this.setInvalidProps(_ret)
        return false
      } else {
        this.setInvalidProps(false)
        return true
      }
    },
    cartProdCount() {
      if (this.checkout === 'instock') {
        return _.keys(this.cartProducts).length
      } else {
        return _.reduce(
          (acc, i) => {
            return (acc += i.units.length)
          },
          0,
          this.cartProducts
        )
      }
    },
    shippingWeight() {
      if (!this.cartProducts || !this.cartProducts.length) return 0
      return this.cartProducts.reduce((acc, i) => {
        acc += i.units
          ? _.sum(i.units.map(u => u.qty * i.weight))
          : i.qty * i.weight
        return acc
      }, 0)
    }
  },
  // component methods
  methods: {
    ...mapActions([
      'getProgram',
      'setCollection',
      'getCartProducts',
      'getPreorderProducts',
      'getCustomorderProducts',
      'getLocations',
      'setShippingAddress',
      'startOrder',
      'updateOrder',
      'checkUser',
      'closeOrder'
    ]),
    setInvalidProps(val) {
      this.fixOrderElements = val
    },
    adjustedPrice(item) {
      const _tiers = _.sortBy(['volume'], item.adjustments)
      let _vol
      let _isCustomized = false
      if (this.checkout == 'reserve') {
        _vol =
          (_.get(
            [
              item.relatedBy === 'unit'
                ? item.SKU
                : item.familyKey + item.variationField.key || ''
            ],
            this.program.summary
          )
            ? this.program.summary[
                item.relatedBy === 'unit'
                  ? item.SKU
                  : item.familyKey + item.variationField.key || ''
              ].volume
            : 0) + item.volume
        if (this.cart.details[item.SKU].customize) _isCustomized = true
      } else {
        _vol = item.qty
      }

      if (!item.adjustments.length || !_vol || _vol < _tiers[0].volume) {
        return {
          val:
            item.price +
            (_isCustomized
              ? typeof item.customUnitPrice === 'number'
                ? item.customUnitPrice
                : this.program.customUnitPrice
              : 0),
          tier: -1,
          subtotal:
            item.qty *
            (item.price +
              (_isCustomized
                ? typeof item.customUnitPrice === 'number'
                  ? item.customUnitPrice
                  : this.program.customUnitPrice
                : 0))
        }
      }
      if (_vol >= _tiers[_tiers.length - 1].volume) {
        console.info(' ::: are we here?')
        return {
          val:
            _tiers[_tiers.length - 1].value +
            (_isCustomized
              ? typeof item.customUnitPrice === 'number'
                ? item.customUnitPrice
                : this.program.customUnitPrice
              : 0),
          tier: _tiers[_tiers.length - 1].volume,
          subtotal:
            item.qty *
            (_tiers[_tiers.length - 1].value +
              (_isCustomized
                ? typeof item.customUnitPrice === 'number'
                  ? item.customUnitPrice
                  : this.program.customUnitPrice
                : 0))
        }
      }
      let _next = _.findIndex(i => i.volume >= _vol)(_tiers)
      _next > 0 ? (_next -= 1) : _next
      return {
        val:
          _tiers[_next].value +
          (_isCustomized
            ? typeof item.customUnitPrice === 'number'
              ? item.customUnitPrice
              : this.program.customUnitPrice
            : 0),
        tier: _tiers[_next].volume,
        subtotal:
          item.qty *
          (_tiers[_next].value +
            (_isCustomized
              ? typeof item.customUnitPrice === 'number'
                ? item.customUnitPrice
                : this.program.customUnitPrice
              : 0))
      }
    },
    adjustedCustomPrice(item) {
      const _tiers = _.sortBy(['volume'], item.custom.adjustments)
      let _logos = this.cart.details[item.SKU]
        ? _.keys(this.cart.details[item.SKU].customizations)
        : []
      let _sorted = _.sortBy(['volume'], item.custom.adjustments)
      if (
        !item.custom.adjustments.length ||
        !_logos.length ||
        item.qty < _tiers[0].volume
      ) {
        return {
          val: item.custom.price,
          unitVal: (_logos.length ? _logos.length - 1 : 0) * item.custom.price,
          // tier: -1,
          locations: _logos,
          subtotal:
            (_logos.length ? _logos.length - 1 : 0) *
            item.qty *
            item.custom.price
        }
      }
      if (item.qty >= _tiers[_tiers.length - 1].volume) {
        return {
          val: _tiers[_tiers.length - 1].value,
          unitVal:
            (_logos.length ? _logos.length - 1 : 0) *
            _tiers[_tiers.length - 1].value,
          // tier: _tiers[_tiers.length-1].volume,
          locations: _logos,
          subtotal:
            (_logos.length ? _logos.length - 1 : 0) *
            item.qty *
            _tiers[_tiers.length - 1].value
        }
      }
      let _logotier = _.findIndex(i => i.volume >= item.qty, _sorted)
      _logotier > 0 ? (_logotier -= 1) : _logotier
      return {
        val: _tiers[_logotier].value,
        unitVal:
          (_logos.length ? _logos.length - 1 : 0) * _tiers[_logotier].value,
        locations: _logos,
        subtotal:
          (_logos.length ? _logos.length - 1 : 0) *
          item.qty *
          _tiers[_logotier].value
      }
    },
    calcTax() {
      if (this.order.groupId) return {}
      // if(!this.order) return 0;
      const addTax =
        this[this.isProgram ? 'program' : 'collection'].settings.shipping
          .type !== 'standard'
      const _subtotal = addTax
        ? this.subtotal + this.order.price.shipping
        : this.subtotal
      this.$http
        .post('/orders/taxes', {
          subtotal: _subtotal,
          taxaddr: this.order.shipping
        })
        .then(data => {
          // this.taxes = _.sum(_.values(data[0]));
          return this.updateOrder({ taxes: data.data })
        })
        .catch(err => {
          console.warn('error calculating tax', err)
          // this.taxes = 0;
        })
    },
    setOrder() {
      // if (_.isEmpty(this.cartProducts)) {
      //   console.info(' :::: SETORDER FAILED 1')
      //   console.info(' ::: Warning: no cart producst')
      //   // no cart items! Redirect...
      // } else if (_.keys(this.cart.items).length !== this.preorderProds.lenth) {
      //   console.info(' :::: SETORDER FAILED 2', this.preorderProds.length, _.keys(this.cart.items).length)
      //   const _to =
      //     this.checkout === 'instock'
      //       ? '/cart/in-stock-gear'
      //       : this.checkout === 'custom'
      //         ? '/cart/customize-it'
      //         : this.program
      //           ? '/shop/programs/' + this.program.url
      //           : '/'
      //   const _diff =
      //     this.checkout === 'instock'
      //       ? _.difference(_.keys(this.cart.items), _.map(i => i.SKU, this.cartProducts)).join(', ')
      //       : _.difference(_.keys(this.cart.items), _.reduce((acc, i) =>
      //           acc.concat(_.map(p => p.SKU, i.units)), [], this.cartProducts
      //         )).join(', ')
      //   const _msg = `Some items in your cart are no longer available for purchase: ${_diff}. If you continue to checkout, these items will be ignored and removed from your cart. If you wish to contact TNT and checkout later, click Cancel.`
      //   this.$vex.dialog.confirm({
      //     message: _msg,
      //     callback: value => {
      //       if (!value) {
      //         this.$router.push({ path: _to })
      //       }
      //     }
      //   })
      // }

      const _type = _.values(this.cart.details)[0].type
      let _ent = this[_type]
      const _opts = {}
      _opts.type =
        this.checkout === 'reserve'
          ? 'reservation'
          : this.checkout === 'custom'
          ? 'custom'
          : _type === 'program'
          ? 'reservation'
          : 'standard'
      // NB: do we need to check for program or collection? Both?
      let _group
      // if (this.program) _ent = this.program
      // else _ent = this.collection
      _opts.collection = _.assign(
        {},
        { id: _ent._id, settings: _ent.settings, type: _ent.type }
      )
      let _shipping = _.assign(
        {},
        this.locations.find(l => l._id === this.user.profile.shipping)
      )
      let _billing = _.assign(
        {},
        this.locations.find(l => l._id === this.user.profile.billing)
      )
      _opts.shipping = {
        location: _shipping._id,
        name: _shipping.name,
        contact: _shipping.contact,
        first: _shipping.owner.profile.first,
        last: _shipping.owner.profile.last,
        email: _shipping.email,
        phone: _shipping.phone,
        address: _shipping.address
      }
      _opts.billing = {
        location: _billing._id,
        name: _billing.name,
        contact: _billing.contact,
        first: _billing.owner.profile.first,
        last: _billing.owner.profile.last,
        email: _billing.email,
        phone: _billing.phone,
        address: _billing.address
      }
      _opts.shipper = _ent.settings.shipping
      _opts.shipper.shippingWeight = this.shippingWeight
      _opts.price = {
        subtotal: this.subtotal,
        shipping: 0,
        taxes: { gst: 0, pst: 0, hst: 0 }
      }
      _opts.items = this.cart.items
      _opts.purchaser = this.user.email
      _opts.paymentMethod = 'cc'
      if (_ent.settings.multilocation) {
        _opts.groupId = uuid()
        _opts.parent = true
        _group = this.shippingLocations.map(l => {
          _opts.shipping = {
            location: l._id,
            name: l.name,
            contact: l.contact,
            email: l.email,
            phone: l.phone,
            address: l.address
          }
          _opts.parentPrice = _.assign({}, _opts.price)
          _opts.items = _.reduce(
            (acc, i) => {
              if (this.cart.details[i].locations[l._id]) {
                acc[i] = this.cart.details[i].locations[l._id]
              }
              return acc
            },
            {},
            _.keys(this.cart.details)
          )
          _opts.price.subtotal = _.reduce(
            (acc, i) => {
              acc += this.cartProducts.length
                ? _.find(p => p.SKU === i, this.cartProducts).price *
                  _opts.items[i]
                : _.find(p => p.SKU === i, _.values(this.cartProducts)).price *
                  _opts.items[i]
              return acc
            },
            0,
            _.keys(_opts.items)
          )
          let p = this.startOrder({
            profile: this.user.profile,
            opts: _opts
          }).then(o => {
            return o
          })
          _opts.parent = false
          if (_opts.parentPrice) delete _opts.parentPrice
          return p
        })
        return Promise.all(_group)
      } else {
        return this.startOrder({ profile: this.user.profile, opts: _opts })
      }
    },
    currencyLocale(val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    }
  }
}
</script>

<style
  src="@/../../../templates/brand/components/styles/shop/checkout/Checkout.css"
></style>
