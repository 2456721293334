<template src="@/../../../templates/brand/components/templates/shop/checkout/Receipt.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import accounting from 'accounting'

export default {
  name: 'Receipt',
  data () {
    return {
      tryAgain: false,
      txnStatus: null
    }
  },
  props: ['valid'],
  computed: {
    ...mapState({
      user: ({ App }) => App.user,
      neworder: ({ Order }) =>
        Order.order || {
          price: { taxes: {} },
          shipping: { address: {} },
          billing: { address: {} },
          txn: {}
        },
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    order () {
      const _order = _.assign({}, this.neworder)
      _order.items = _.sortBy(['product.SKU'], _order.items)
      return _order
    },
    shippingVals () {
      if (!this.order || !this.order.shippingService) return ''
      return _.assign(this.order.shippingService, {
        service: this.order.shippingService.rate.service
          ? this.order.shippingService.rate.service.name
          : this.order.shippingService.type
      })
    }
  },
  methods: {
    ...mapActions([
      'getOrder',
      'cancelOrder',
      'mergeTxn',
      'profileUpdate',
      'clearCartMemory',
      'removeTxn',
      'completeOrder',
      'forgetOrder'
    ]),
    showReceipt: function (complete = true) {
      const _order = _.assign({}, this.order)
      this.emptyCart()
      if (complete)
        this.completeOrder({
          id: this.order._id,
          order: _.assign(_order, { newtxn: true })
        })
    },
    endOrder: function (id) {
      this.emptyCart()
      this.cancelOrder(id)
    },
    emptyCart: function () {
      const _profile = _.assign({}, this.user.profile)
      const _cart =
        _.get(this.$route.query.cartid, _profile) ||
        _.get(this.$route.query.cartid, _profile.shoppingcarts)
      _cart.items = {}
      _cart.details = {}
      this.profileUpdate({ user: this.user, profile: _profile })
      this.clearCartMemory('cart')
    },
    currencyLocale: function (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    serviceDescription: function (service) {
      if (service.carrier === 'UPS') {
        return 'UPS: ' + !this.order.groupId
          ? this.appconfig.locale[this.$i18n.locale].upsServiceTypeMap[service]
          : this.appconfig.locale[this.$i18n.locale].upsServiceTypeMap[
              this.order.orders[0].shippingService
            ]
      } else {
        // console.info(' ::: not UPS, get from shippingvals')
        return service.carrier + ': ' + this.shippingVals.service
      }
    }
  },
  beforeDestroy () {
    this.forgetOrder()
  },
  created: function () {
    if (!this.order._id) {
      this.$router.push({ path: '/checkout', params: { recycle: true } })
    }
    if (this.$route.path === '/vault' || this.$route.meta.vault) {
      this.showReceipt(false)
      return
    }

    window.history.replaceState(
      'object or string',
      'Title',
      window.location.href.replace(window.location.search, '')
    )
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/checkout/Receipt.css"></style>
