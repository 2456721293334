<template src="@/../../../templates/brand/components/templates/shop/checkout/shipping/CanPostRates.html">
</template>

<script>
import _ from 'lodash/fp'
import { mapGetters } from 'vuex'
import accounting from 'accounting'

export default {
  name: 'CanPost',
  mounted () {
    this.$nextTick(() => {
      this.$emit('carrierinit', {
        carrier: 'CanPost',
        index: 0,
        rate: this.rates[0],
        price: this.rates[0].price.adjustedBase || this.rates[0].price.base,
        serviceCode: this.rates[0].service.code
      })
    })
  },
  props: {
    rateData: {
      type: Array,
      default: () => []
    },
    multilocation: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object
    }
  },
  data () {
    return {
      errorMsg: '',
      shippingMsg: '',
      remote: false,
      // selected: {}
    }
  },
  computed: {
    ...mapGetters({
      appconfig: 'const'
    }),
    rates () {
      // if (!this.rateData || !this.rateData.CanPost) return [];
      return _.sortBy(
        i => i.price.adjustedBase || i.price.base,
        this.rateData.slice().map(r => ({...r, carrier: 'CanPost'}))
      )
    },
    multiloc () {
      return this.multilocation
    }
  },
  methods: {
    serviceDescription (service) {
      return this.appconfig.locale[this.$i18n.locale].upsServiceTypeMap[service]
    },
    currencyLocale (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    select (rate) {
      if (this.remote) {
        this.remote = false
        return
      }
      if (rate.carrier !== 'CanPost') {
        // this.selected = {}
        return
      }
      this.selected = rate
      this.$emit('input', {
        carrier: 'CanPost',
        serviceCode: rate.service.code,
        rate: parseFloat(rate.price.adjustedBase || rate.price.base),
        currency: 'CDN',
        // billingWeight: parseInt(rate.BillingWeight.Weight, 10),
        rawdata: _.assign({}, rate)
      })
    }
  },
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/checkout/shipping/CanPostRates.css"></style>
