<template
  src="@/../../../templates/brand/components/templates/shop/checkout/Reserve.html"
>
</template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import accounting from 'accounting'
import acl from '@/../../../templates/site/UI/app/acl-rules'
import CreditProfiles from '@/components/CreditProfiles.vue'

export default {
  name: 'Reserve',
  mounted() {
    // canChangeCreditAmount is a configurable option to let purchaser specify amount of credit used...
    if (this.canChangeCreditAmount) {
      this.$watch('creditAmount', () => {
        if (this.creditAmount < 0) this.setCreditAmount(this.calculatedTotal)
        if (this.creditAmount > this.calculatedTotal) {
          if (this.calculatedTotal > this.creditBalance) {
            this.setCreditAmount(this.creditBalance)
          } else {
            this.setCreditAmount(this.calculatedTotal)
          }
        } else if (this.creditAmount > this.creditBalance) {
          this.setCreditAmount(this.creditBalance)
        }

        this.contributeCredit(this.creditAmount)
        console.info(
          'watching creditAmount. allcredit',
          this.allCredit,
          ', some',
          this.someCredit,
          ', balance',
          this.creditBalance,
          ', amount',
          this.creditAmount,
          ', total',
          this.order.price
        )
      })
    }
  },
  components: { CreditProfiles },
  data() {
    return {
      paymentType: 'cc',
      poNumber: '',
      creditAmount: 0,
      useCredit: false,
      selectedCC: null,
      // moneris_hpp_url: '',
      // moneris_tokenization_id: '',
      // // paymentMethods: this.appconfig.siteConfig.paymentMethods,
      submitted: false,
      hide: false // this is to prevent flash of content during completeOrder if deferPayment
    }
  },
  props: ['valid', 'cart', 'products', 'checkout'],
  methods: {
    ...mapActions([
      'getCerts',
      'createOrder',
      'updateOrder',
      'clearCartMemory',
      'setOrderMethod',
      'setOrderProfile',
      'contributeCredit',
      'forgetOrder'
    ]),
    setCreditAmount(val) {
      let _val = val
      if (val) {
        if (this.someCredit) {
          _val =
            this.order.shippingService.shippingTBD &&
            this.order.price.subtotal < this.creditBalance
              ? this.order.price.subtotal
              : this.creditBalance
        } else if (this.allCredit) {
          _val = this.calculatedTotal
        }
      }
      this.creditAmount = Number(accounting.toFixed(_val, 2))
    },
    changePaymentType(val) {
      // if (this.paymentType === val) return;

      if (val === 'credit') {
        if (this.creditBalance) {
          this.useCredit = !this.useCredit
          if (this.useCredit) {
            this.setCreditAmount(
              this.someCredit ? this.creditBalance : this.calculatedTotal
            )
            if (this.allCredit) this.paymentType = 'credit'
          } else {
            this.setCreditAmount(0)
            if (this.paymentType === 'credit') this.paymentType = 'cc'
          }
          this.contributeCredit(this.creditAmount)
        }
      } else {
        if (this.useCredit && this.allCredit) {
          this.useCredit = false
          this.setCreditAmount(0)
          this.contributeCredit(this.creditAmount)
        }
        this.paymentType = val
      }
    },
    toggleCredit(evt) {
      if (evt.target.checked) {
        this.setCreditAmount(this.creditBalance)
      } else {
        this.setCreditAmount(0)
      }
      this.contributeCredit(this.creditAmount)
    },
    calcTax() {
      if (!this.order.groupId) {
        return this.$http
          .post('/orders/taxes', {
            subtotal: this.order.price.subtotal + this.order.price.shipping,
            taxaddr: this.order.shipping
          })
          .then(data => {
            // this.taxes = _.sum(_.values(data[0]));
            this.updateOrder({ taxes: data.data })
            return true
          })
          .catch(err => {
            console.warn('error calculating tax', err)
            return false
            // this.taxes = 0;
          })
      } else {
        return this.$http
          .post('/orders/taxes', {
            subtotal: null,
            taxaddr: null,
            multilocation: true,
            orders: _.map(
              o => ({
                subtotal: o.price.subtotal + o.price.shipping,
                taxaddr: o.shipping
              }),
              _.values(this.order.orders)
            )
          })
          .then(data => {
            this.updateOrder({
              taxes: true,
              multilocation: true,
              data: data[0].data
            })
            return true
          })
          .catch(err => {
            console.warn('error calculating tax', err)
            return false
            // this.taxes = 0;
          })
      }
    },
    /**
     * sets an arbitrary value on the order. Mainly useful for adding extradata properties
     * for agreement modals etc.
     * @param {string} prop - dot delimited property path on the order object
     * @param {DOMElement} target - the target of the event
     */
    setOrderVal(prop, target) {
      const val =
        target.type === 'checkbox'
          ? target.checked
            ? target.value
            : undefined
          : target.value
      this.updateOrder(_.set(prop, val, _.assign({}, this.order)))
    },
    completeCheckout() {
      if (!this.canCheckout) return
      this.submitted = true
      if (this.paymentType.toLowerCase() === 'po')
        this.order.referenceNum = this.poNumber
      if (this.useCredit && this.creditAmount)
        this.contributeCredit(this.creditAmount)
      this.setOrderMethod(this.paymentType)
      if (this.paymentType === 'cc') this.setOrderProfile(this.selectedCC)

      if (this.$route.name === 'checkoutPayment') {
        this.$http.post('/orders', this.order).then(res => {
          const _res = res.data
          // console.info(' ::::::: THIS IS RES', _res)
          // new test res for success -- should be txn and success...
          if (_res.success) {
            this.updateOrder(_res.data ? _res.data.order : _res.order) // really?
            this.clearCartMemory(this.$route.query.cartid)
            this.$router.push({
              name: 'receipt',
              query: this.$route.query
            })
          } else if (_res.error && _res.error === 'inventory') {
            this.$vex.dialog.alert({
              unsafeMessage:
                'Inventory has changed since you started checkout!<b>' +
                _res.data.map(
                  i => `${i.SKU}: cart - ${i.cart}, in stock - ${i.inventory}`
                ),
              callback: () => {
                // TODO: this.cart isn't defined here, cartid should be on the route. Fix cart routes
                this.$router.push({
                  path:
                    '/cart/' +
                    this.cart.details[_.keys(this.cart.details)[0]].url,
                  query: this.$route.query
                })
              }
            })
          } else if (this.paymentType === 'cc') {
            let _msg = `<h3>Transaction Failed</h3>
												<p>There was a problem with the payment.</p>`
            _msg += _res.error
              ? `<p>${_res.error}</p>`
              : `<p>${_res.txn.Message}</p>`
            _msg +=
              '<p>Go to <em>myAccount/paymentMethods</em> if you need to update your card info before trying again.</p>'
            this.$vex.dialog.alert({
              unsafeMessage: _msg
            })
          } else if (_res.code && _res.code === 'PO-001') {
            this.$vex.dialog.alert({
              unsafeMessage:
                'Your order total is below the required minimum for PO. Please select another payment method.',
              callback: () => {
                this.changePaymentType('cc')
              }
            })
          } else {
            let _msg = []
            if (_res.error) _msg.push('Error message: ' + _res.error)
            if (_res.msg) _msg.push(_res.msg)
            if (_res.code) _msg.push(` (code: ${_res.code})`)
            this.$vex.dialog.alert({
              unsafeMessage:
                'There was an error completing your order: ' + _msg.join(', '),
              callback: () => {
                this.$router.push({ name: this.$route.name })
              }
            })
          }
        })
      } else if (
        this.$route.name === 'customCheckout' ||
        this.checkout === 'custom'
      ) {
        this.$http.post('/orders', this.order).then(res => {
          console.info('saved', res.data.order.referenceNum)
          if (res.data.success) {
            this.updateOrder(res.data.order)
            this.clearCartMemory(this.$route.query.cartid)
            this.$router.push({
              name: 'customReceipt',
              query: this.$route.query
            })
          } else {
            this.$vex.dialog.alert({
              unsafeMessage:
                'There was an error completing your order: ' + res.data.message,
              callback: () => {
                // this.$router.push({ name: this.$route.name })
              }
            })
          }
        })
      } else {
        this.$http
          .post(`programs/${this.$route.params.program}/reserve`, this.order)
          .then(res => {
            this.updateOrder(res.data.reservation)
            this.clearCartMemory('preorders')
            this.$router.push({
              name: 'reservationReceipt',
              query: this.$route.query
            })
          })
      }
    },
    currencyLocale(val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    creditProfileCallback(type, data) {
      switch (type) {
        case 'start':
          this.selectedCC = data
          break
        case 'select':
          this.selectedCC = data
          break
      }
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user,
      order: ({ Order }) => Order.order,
      ecerts: ({ ECert }) => ECert.certs,
      program: ({ Collection }) => Collection.program,
      collection: ({ Collection }) => Collection.collection,
      locations: ({ Location }) => Location.locations,
      userLocale: ({ active }) => active + '-ca'
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    gatewayData() {
      const data = {
        moneris_hpp_url: '',
        moneris_tokenization_id: ''
      }
      if (
        !this.appconfig ||
        !this.appconfig.siteConfig ||
        !this.appconfig.siteConfig.checkout
      ) {
        return data
      }
      data.moneris_hpp_url = this.appconfig.siteConfig.checkout.gatewayHost
      data.moneris_tokenization_id = this.appconfig.siteConfig.checkout.profileId
      return data
    },
    orderValid() {
      return true
    },
    paymentMethods() {
      // let _methods = _.assign({}, this.appconfig.siteConfig.paymentMethods)
      const allowedMethods = acl['payment.methods'](this.user.role.level)
      const _methods = _.reduce(
        (acc, i) => {
          acc[i] = !!(
            this.appconfig.siteConfig.paymentMethods[i] &&
            ~allowedMethods.indexOf(i)
          )
          return acc
        },
        {},
        Object.keys(this.appconfig.siteConfig.paymentMethods)
      )
      // }, {}, acl['payment.methods'](this.user.role.level))
      if (!_methods.po && this.user.profile.extradata.paymentLevel) {
        _methods.po = _.reduce(
          (acc, i) => {
            acc[i] = true
            return acc
          },
          {},
          acl['payment.methods'](this.user.profile.extradata.paymentLevel)
        ).po
      }
      if (
        ~['delinquent'].indexOf(this.user.status) ||
        (this.appconfig.siteConfig.POMinimum &&
          this.order.price.subtotal < this.appconfig.siteConfig.POMinimum)
      ) {
        _methods.po = false
      }
      return _methods
    },
    billingAddress() {
      if (this.order) {
        return _.assign({}, this.order.billing)
      } else
        return _.assign(
          {},
          this.locations.find(l => l._id === this.user.profile.billing)
        )
    },
    ccOnly() {
      if (
        !this.paymentMethods.po &&
        !this.paymentMethods.credit &&
        this.paymentMethods.cc
      )
        return true
      const _lvl =
        this.user.profile.extradata.paymentLevel || this.user.role.level
      return acl['cc.only'](_lvl)
    },
    creditAllowed() {
      return this.paymentMethods.credit && this.creditBalance
    },
    creditBalance() {
      return _.reduce((sum, i) => sum + i.balance, 0, this.ecerts)
    },
    allCredit() {
      return this.creditBalance >= this.calculatedTotal
    },
    someCredit() {
      return this.creditBalance && this.creditBalance < this.calculatedTotal
    },
    calculatedTotal() {
      if (!this.order.shippingService)
        return (
          this.order.price.subtotal + _.sum(_.values(this.order.price.taxes))
        )
      return this.order.shippingService.shippingTBD
        ? this.order.price.subtotal +
            this.order.shippingService.rate +
            _.sum(_.values(this.order.price.taxes))
        : this.order.price.subtotal +
            this.order.price.shipping +
            _.sum(_.values(this.order.price.taxes))
    },
    canChangeCreditAmount() {
      return this.appconfig.userConfig.checkout.setCreditContrib
    },
    canCheckout() {
      if (this.submitted) return false
      if (this.allCredit && this.paymentType === 'credit') return true
      if (this.paymentType === 'po') return true
      if (this.addCard) return false
      if (!this.selectedCC) return false
      if (
        this.order.shippingService.type === 'foreign' &&
        (!this.order.extradata ||
          !this.order.extradata.foreignShippingAgreement)
      )
        return false
      return true
    },
    showAdd() {
      return !this.selectedCC
    }
  },
  created() {
    this.submitted = false
    const _isProgram = Object.values(this.cart.details)[0].type === 'program'
    this.hide = !!(_isProgram
      ? this.program.settings.deferPayment
      : this.collection.settings.deferPayment)
    if (
      _.isEmpty(this.order) ||
      _.isEmpty(this.order.price) ||
      !(
        this.order.price.shipping ||
        ~['included', 'deferred'].indexOf(this.order.shippingService.type) ||
        this.order.price.shippingTBD
      )
    ) {
      // this.$router.replace(this.$route.path.replace('/payment', ''))
      this.$router.back()
    } else {
      this.paymentType = 'cc'
      this.poNumber = ''
      this.creditAmount = 0
      this.useCredit = false

      this.calcTax().then(res => {
        if (!res) {
          return // and what?
          // return transition.next()
        }
        if (
          (_isProgram && this.program.settings.deferPayment) ||
          (!_isProgram && this.collection.settings.deferPayment)
        ) {
          // this.$nextTick(() => {
          this.paymentType = 'po'
          this.completeCheckout()
          // })
        }
      })
    }
    if (this.paymentMethods.credit) {
      this.getCerts().then(() => {
        setTimeout(() => {
          if (this.allCredit) {
            this.changePaymentType('credit')
          } else if (this.someCredit) {
            this.setCreditAmount(this.creditBalance)
            this.contributeCredit(this.creditAmount)
            this.useCredit = true
          }
        }, 100)
      })
    }
  }
}
</script>

<style
  src="@/../../../templates/brand/components/styles/shop/checkout/Reserve.css"
></style>
