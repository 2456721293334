<template src="@/../../../templates/brand/components/templates/shop/checkout/Shipping.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import accounting from 'accounting'
// import { Address } from '@/models/ProfileModel'
import UpsRates from '@/components/shop/shipping/UPSRates.vue'
import CanpostRates from '@/components/shop/shipping/CanPostRates.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'Shipping',
  data () {
    return {
      shippingTypes: [],
      loaded: false,
      loading: false,
      loadingMsg: '',
      rates: {},
      rateProjections: [],
      defaultRate: {},
      errorMsg: null,
      shippingMsg: null
    }
  },
  props: ['valid', 'cart', 'products', 'checkout', 'collection'],
  components: { UpsRates, CanpostRates, Loader },
  methods: {
    ...mapActions(['setOrderShipping', 'updateOrder', 'clearShippingData']),
    currencyLocale (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    calcTax () {
      if (!this.order.groupId) {
        this.$http
          .post('/orders/taxes', {
            subtotal: this.order.price.subtotal + this.order.price.shipping,
            taxaddr: this.order.shipping,
            multilocation: false,
            orders: false
          })
          .then(res => {
            // this.taxes = _.sum(_.values(data[0]));
            this.updateOrder({ taxes: res.data })
          })
          .catch(err => {
            console.warn('error calculating tax', err)
            // this.taxes = 0;
          })
      } else {
        this.$http
          .post('/ordes/taxes', {
            subtotal: null,
            taxaddr: null,
            multilocation: true,
            orders: _.map(
              o => ({
                subtotal: o.price.subtotal + o.price.shipping,
                taxaddr: o.shipping
              }),
              _.values(this.order.orders)
            )
          })
          .then(res => {
            this.updateOrder({
              taxes: true,
              multilocation: true,
              data: res.data
            })
          })
          .catch(err => {
            console.warn('error calculating tax', err)
            // this.taxes = 0;
          })
      }
    },
    initRates (select) {
      this.rateProjections.push(select)
      if (this.rates.multilocation) {
        if (
          this.rateProjections.length ===
          _.keys(_.values(this.rates.rates)[0]).length
        ) {
          // initialized all
          this.defaultRate = _.sortBy('price', this.rateProjections)[0]
          this.setRate(select)
        }
      } else {
        if (this.rateProjections.length === _.keys(this.rates).length) {
          // initialized all
          this.defaultRate = _.sortBy('price', this.rateProjections)[0]
          this.setRate(select)
        }
      }
    },
    setRate (rate) {
      this.setOrderShipping({
        selected: _.assign({}, rate),
        rates: _.assign({}, this.rates)
      })
      this.$nextTick(() => {
        this.calcTax()
      })
    }
  },
  computed: {
    ...mapState({
      order: ({ Order }) => Order.order,
      user: ({ App }) => App.user
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    totalWeight () {
      if (!this.products) return 0
      let _done = []
      return _.reduce(
        (sum, i) => {
          if (this.checkout === 'instock') return sum + i.weight * i.qty
          if (!~_done.indexOf(i.familyKey + (i.variationKey || ''))) {
            _done.push(i.familyKey + (i.variationKey || ''))
            return sum + i.weight * i.qty
          } else {
            return sum
          }
        },
        0,
        this.products
      )
    },
    shippingAddress: function () {
      if (this.order && this.order.shipping && this.order.shipping.last) {
        return this.order.shipping
      } else if (this.order.groupId) {
        // multi-location
        return _.map(
          o => ({
            location: o.shipping,
            items: _.keys(o.items).length,
            units: _.sum(_.values(o.items))
          }),
          _.values(this.order.orders)
        )
      } else {
        return _.assign({}, this.user.profile.shipping)
      }
    },
    userLocations () {
      if (this.user.authenticated) {
        return this.user.profile.addresses
      }
      return []
    },
    shippingAddressValid () {
      return true
      // if (this.order.groupId) return true
      // // const _addr = new Address(this.shippingAddress.address)
      // return (
      //   this.shippingAddress.first &&
      //   this.shippingAddress.last &&
      //   this.shippingAddress.email &&
      //   this.shippingAddress.phone &&
      //   this.shippingAddress.address.street &&
      //   this.shippingAddress.address.city &&
      //   this.shippingAddress.address.prov &&
      //   this.shippingAddress.address.country &&
      //   this.shippingAddress.address.code
      //   // _addr.validate()
      // )
    },
    aggregateRates () {
      if (_.isEmpty(this.rates) || !this.rates.multilocation) return false
      const _rates = _.assign({}, this.rates.rates)
      return _.reduce(
        (acc, i) => {
          for (let carrier of _.keys(i)) {
            if (!acc[carrier]) acc[carrier] = []
            for (let service of carrier === 'UPS'
              ? i[carrier].RatedShipment.slice()
              : i[carrier].slice()) {
              const _code = carrier === 'UPS' ? service.Service.Code : service.service.code
              if (carrier === 'UPS') {
                if (_.isEmpty(acc[carrier]))
                  acc[carrier] = { RatedShipment: [] }
                let _r = acc[carrier].RatedShipment.find(
                  s => s.Service && s.Service.Code === _code
                )
                if (!_r)
                  acc[carrier].RatedShipment.push({
                    Service: { Code: _code },
                    TotalCharges: {
                      AdjustedRate: Number(service.TotalCharges.AdjustedRate),
                      MonetaryValue: Number(service.TotalCharges.MonetaryValue)
                    },
                    BillingWeight: { Weight: service.BillingWeight.Weight }
                  })
                else {
                  _r.TotalCharges.AdjustedRate += Number(
                    service.TotalCharges.AdjustedRate
                  )
                  _r.TotalCharges.MonetaryValue += Number(
                    service.TotalCharges.MonetaryValue
                  )
                }
              } else {
                let _r = acc[carrier].find(
                  s => s.service && s.service.code === _code
                )
                if (!_r)
                  acc[carrier].push({
                    service: { code: _code, name: service.service.name },
                    price: {
                      adjustedBase: service.price.adjustedBase,
                      rate: service.price.base
                    }
                  })
                else {
                  _r.price.adjustedBase += service.price.adjustedBase
                  _r.price.rate += service.price.base
                }
              }
            }
          }
          return acc
        },
        {},
        _rates
      )
    }
  },
  created () {
    this.loaded = false
    this.rates = {}
    this.loadingMsg = ''
    this.errorMsg = ''
    this.shippingMsg = ''

    if (_.isEmpty(this.order) || _.isEmpty(this.order.price)) {
      this.$router.replace('/checkout')
      return
    }

    // handle single orders
    if (!this.order.groupId) {
      // // if order is too heavy... do we apply this per-service?
      // /* 
      //   yes. But do we need the update part? Does that already happen when starting the order?

      //   test for conditions where we need to a show a message (eg, overwieght, above top tier)
      //   then set showShippingMessage to true and set shippingMsg to some translation string's key
      //   totalWeight > appconfig.siteConfig.maxShippingWeight
      //   order.shippingservice.type === tiered && !~order.shippginService.tiers.findIndex(order.subtotal)
      // */
      // if (
      //   this.appconfig.siteConfig.quoteOnHeavyOrders &&
      //   this.totalWeight > this.appconfig.siteConfig.maxShippingWeight
      // ) {
      //   this.shippingMsg = this.appconfig.siteConfig.deferShippingMessage
      //   this.loaded = true
      //   this.updateOrder({
      //     shippingTBD: true,
      //     rate: this.appconfig.siteConfig.TBDShippingRate,
      //     currency: 'CAD',
      //     billingWeight: this.totalWeight
      //   })
      //   return
      // }
      //
      if (!this.user || _.isEmpty(this.order)) this.$router.replace('/checkout')
      const _data = _.assign({}, this.order.shipping)
      _data.weight = this.totalWeight
      // let _maxTries = 3

      // function callUPS (ctx) {
      this.loading = true
      // this.loadingMsg = 'Contacting UPS'
      this.$http
        .post(`/orders/shipping`, _data)
        .then(rates => {
          if (rates.data.error) {
            console.info(rates.data.error)
            if (~[110000, 110001].indexOf(rates.data.error.data.ErrorCode)) {
              // service unavailable. Wait for it...
              // if (_maxTries--) {
              //   let _count = 5
                this.loadingMsg = `UPS rating service is unavailable. Try again in a minute...`
                // this.loadingMsg = `UPS rating service is unavailable. Trying again in 5 seconds...`
              //   let _timer = setInterval(() => {
              //     this.loadingMsg = `UPS rating service is unavailable. Trying again in ${--_count} seconds...`
              //   }, 1000)
              //   setTimeout(() => {
              //     window.clearInterval(_timer)
              //     this.loadingMsg = ''
              //     callUPS(this)
              //   }, 5000)
              // } else {
                this.loading = false
                // this.loadingMsg = ''
                // this.errorMsg = rates.data.error.error // `The UPS rating service seems to be having a serious problem. Please try to checkout a bit later. Sorry for the inconvenience.`
              // }
            } else {
              this.loading = false
              this.loadingMsg = ''
              this.errorMsg = rates.data.error.error
              // ctx.errorMsg = `The UPS rating service returned an error: ${rates.data.data.ErrorDescription}, (code: ${rates.data.data.ErrorCode}). If you need help resolving the error, please contact an administrator.`
            }
          } else {
            this.rates = rates.data.data
            // this.defaultRate = {name: 'nobody'}

            this.loaded = true
            this.loading = false
          }
        })
        .catch(err => {
          console.error(' :: error getting shipping for order', err)
          this.errorMsg = `UPS rating service is unavailable. Try again in a minute...`
        })
    } else {
      this.loaded = false
      // multi-location order
      const _data = { multilocation: true, ship: [], defer: [] }
      for (let _order of _.values(this.order.orders)) {
        // if this.products is a vue object, get the record array...
        const _prods = this.products.length
          ? this.products
          : _.values(this.products)
        let _w = _.reduce(
          (acc, k) => {
            acc += _prods.find(p => p.SKU === k).weight * _order.items[k]
            return acc
          },
          0,
          _.keys(_order.items)
        )
        if (
          this.appconfig.siteConfig.quoteOnHeavyOrders &&
          _w > this.appconfig.siteConfig.maxShippingWeight
        ) {
          this.updateOrder({
            multilocation: true,
            location: _order.shipping.first,
            shippingTBD: true,
            rate: this.appconfig.siteConfig.TBDShippingRate,
            currency: 'CAD',
            billingWeight: _w
          })
          _data.defer.push({
            location: _order.shipping.first,
            billingWeight: _w
          })
        } else {
          _data.ship.push({ order: _order, billingWeight: _w })
        }
      }

      if (!_data.ship.length) {
        this.shippingMsg = this.appconfig.siteConfig.deferShippingMessage
        this.loaded = true
        return
      } else if (_data.defer.length) {
        this.shippingMsg =
          this.appconfig.siteConfig.deferShippingMessage +
          ` (${_data.defer.length} of ${_.values(this.order.orders).length})`
      } else {
        this.shippingMsg = ''
      }

      this.loading = true
      this.$http
        .post(`/orders/${this.order._id}/shipping`, _data)
        .then(rates => {
          if (!rates.data.success) {
            console.error(' ::: error calling rates API')
          }
          if (rates.data.error) {
            console.error(' ::: shipping error', rates.data.error)
            this.errorMsg = rates.data.error
            this.loading = false
            this.loaded = true
          } else {
            this.rates = _.assign(_data, {
              rates: _.assign({}, rates.data.data)
            })
            this.loading = false
            this.loaded = true
          }
        })
    }
  },
  beforeDestroy () {
    if (!this.defaultRate && !this.order.shippingService) {
      let _select = _.reduce(
        (r, acc) => {
          if (!acc || acc.price > r.price) acc = r
          return acc
        },
        null,
        this.rateProjections
      )
      this.$emit('input', _select)
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/checkout/Shipping.css"></style>
