<template src="@/../../../templates/brand/components/templates/shop/checkout/shipping/UPSRates.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapGetters } from 'vuex'
import accounting from 'accounting'

export default {
  created () {
    this.$nextTick(() => {
      this.$emit('carrierinit', {
        carrier: 'UPS',
        index: 0,
        rate: this.rates[0],
        price: parseFloat(this.rates[0].TotalCharges.AdjustedRate),
        serviceCode: this.rates[0].Service.Code
      })
    })
  },
  props: {
    rateData: {
      type: Object,
      default: () => {}
    },
    multilocation: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object
    }
  },
  data () {
    return {
      errorMsg: '',
      shippingMsg: '',
      selected: {}
    }
  },
  computed: {
    ...mapGetters({
      appconfig: 'const'
    }),
    rates () {
      // if (!this.rateData || !this.rateData.UPS) return [];
      return _.sortBy(
        i => parseFloat(i.TotalCharges.MonetaryValue),
        this.rateData.RatedShipment.slice().map(r => ({...r, carrier: 'UPS'}))
      )
    }
  },
  methods: {
    serviceDescription (service) {
      return this.appconfig.locale[this.$i18n.locale].upsServiceTypeMap[service]
    },
    currencyLocale (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    select (rate) {
      if (rate.carrier !== 'UPS') {
        this.selected = {}
        return
      }
      this.selected = rate
      this.$emit('input', {
        carrier: 'UPS',
        serviceCode: rate.Service.Code,
        rate: parseFloat(rate.TotalCharges.AdjustedRate),
        currency: rate.TotalCharges.CurrencyCode,
        billingWeight: parseInt(rate.BillingWeight.Weight, 10),
        rawdata: _.assign({}, rate)
      })
    }
  },
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/checkout/shipping/UPSRates.css"></style>
