<template src="@/../../../templates/brand/components/templates/shop/checkout/ReservationReceipt.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import accounting from 'accounting'

export default {
  name: 'ReservationReceipt',
  data () {
    return {
      locales: _.keys(this.$store.state.lang),
      shippingType: 'ups'
    }
  },
  created () {
    if (!this.order._id) this.$router.replace('/')
  },
  beforeDestroy () {
    this.forgetOrder()
  },
  methods: {
    ...mapActions(['forgetOrder']),
    adjustedPrice (item) {
      const _tiers = _.sortBy(a => a.volume, item.product.adjustments)
      const _vol = this.order.extradata.summary[
        item.product.familyKey +
          ((item.product.variationField && item.product.variationField.key) ||
            item.product.variationKey ||
            '')
      ].volume

      if (
        !item.product.adjustments.length ||
        !_vol ||
        _vol < _tiers[0].volume
      ) {
        return {
          val:
            item.product.price +
            (item.customize
              ? typeof item.customUnitPrice === 'number'
                ? item.customUnitPrice
                : this.program.customUnitPrice
              : 0),
          tier: -1,
          subtotal:
            item.quantity *
            (item.product.price +
              (item.customize
                ? typeof item.customUnitPrice === 'number'
                  ? item.customUnitPrice
                  : this.program.customUnitPrice
                : 0))
        }
      }
      if (_vol > _tiers[_tiers.length - 1].volume) {
        return {
          val:
            _tiers[_tiers.length - 1].value +
            (item.customize
              ? typeof item.customUnitPrice === 'number'
                ? item.customUnitPrice
                : this.program.customUnitPrice
              : 0),
          tier: _tiers[_tiers.length - 1].volume,
          subtotal:
            item.quantity *
            (_tiers[_tiers.length - 1].value +
              (item.customize
                ? typeof item.customUnitPrice === 'number'
                  ? item.customUnitPrice
                  : this.program.customUnitPrice
                : 0))
        }
      }
      let _next = _.findIndex(i => i.volume >= _vol - 1)(_tiers)
      _next > 0 ? (_next -= 1) : _next
      return {
        val:
          _tiers[_next].value +
          (item.customize
            ? typeof item.customUnitPrice === 'number'
              ? item.customUnitPrice
              : this.program.customUnitPrice
            : 0),
        tier: _tiers[_next].volume,
        subtotal:
          item.quantity *
          (_tiers[_next].value +
            (item.customize
              ? typeof item.customUnitPrice === 'number'
                ? item.customUnitPrice
                : this.program.customUnitPrice
              : 0))
      }
    },
    customLogos (item) {
      return _.keys(item.customize)
    },
    currencyLocale (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    serviceDescription (service) {
      if (service.carrier === 'UPS') {
        return (
          'UPS: ' + this.appconfig.locale[this.$i18n.locale].upsServiceTypeMap[service]
        )
      } else {
        return service.carrier + ': ' + this.shippingVals.service
      }
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user,
      program: ({ Collection }) => Collection.program,
      order: ({ Order }) => Order.order || {},
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    valid () {
      return this.order.shippingService && this.order.paymentMethod
    },
    shippingVals () {
      if (!this.order || !this.order.shippingService) return ''
      return _.assign(this.order.shippingService, {
        service: this.order.shippingService.rate.service
          ? this.order.shippingService.rate.service.name
          : this.order.shippingService.type
      })
      // return shippingMap(
      //   this.order.shippingService.rawdata,
      //   this.appconfig.siteConfig.shippingMap[this.order.shippingService.carrier]
      // )
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/checkout/ReservationReceipt.css"></style>
