<template src="@/../../../templates/brand/components/templates/shop/checkout/CustomerInfo.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState } from 'vuex'
// import { Address } from '@/models/ProfileModel'

export default {
  name: 'CustomerInfo',
  created () {
    // this.getLocations()
  },
  data () {
    return {
      showShippingForm: false,
      shippingform: { address: {} },
      showBillingForm: false,
      billingform: { address: {} },
      selectFromLocs: false,
      // shippingPath: '/checkout/shipping',
      provOptions: [
        { text: 'Alberta', value: 'AB' },
        { text: 'British Columbia', value: 'BC' },
        { text: 'Manitoba', value: 'MB' },
        { text: 'New Brunswick', value: 'NB' },
        { text: 'Newfoundland and Labrador', value: 'NL' },
        { text: 'Nova Scotia', value: 'NS' },
        { text: 'Northwest Territories', value: 'NT' },
        { text: 'Nunavut', value: 'NU' },
        { text: 'Ontario', value: 'ON' },
        { text: 'Prince Edward Island', value: 'PE' },
        { text: 'Quebec', value: 'QC' },
        { text: 'Saskatchewan', value: 'SK' },
        { text: 'Yukon', value: 'YT' }
      ],
      coOptions: [{ text: 'Canada', value: 'ca' }]
    }
  },
  props: ['valid', 'products', 'collection', 'checkout', 'cart'],
  mounted () {
    this.shippingform = _.assign({}, this.shippingAddress)
    this.billingform = _.assign({}, this.billingAddress)
  },
  methods: {
    ...mapActions(['getLocations', 'setShippingAddress', 'setBillingAddress']),
    setAddressFromLocs (evt) {
      // const _form = _.assign({}, this.user.profile);
      const _type = evt.target.options[evt.target.selectedIndex].dataset.type
      const _val = evt.target.value
      const _loc = this.locations.find(l => l._id === _val)
      const _addr = {
        address: _loc.address,
        email: _loc.email,
        phone: _loc.phone,
        location: _loc._id,
        name: _loc.name,
        contact: _loc.contact || `${_loc.owner.profile.last} ${_loc.owner.profile.first}`,
        last: _loc.owner.profile.last,
        first: _loc.owner.profile.first
      }
      if (_type === 'shipping') {
        this.setShippingAddress(_addr)
        this.showShippingForm = false
      } else {
        this.setBillingAddress(_addr)
        this.showBillingForm = false
      }
    },
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user,
      order: ({ Order }) => Order.order,
      // collection: ({ Collection }) => Collection.collection,
      // program: ({ Collection }) => Collection.program,
      locations: ({ Location }) => Location.locations
    }),
    calculateShipping () {
      return this.collection.settings.shipping.type === 'standard' && !this.order.shippingService.shippingTBD
    },
    shippingMessage () {
      if (!this.order || !this.order.shippingService) return false

      const settings = this.collection.settings
      if (settings.shipping.type === 'standard' && this.order.shippingService.shippingTBD) {
        return 'shippingStandardOverweight'
      }
      if (settings.shipping.type === 'tiered' && this.order.shippingService.shippingTBD) {
        return 'shippingTieredOver'
      }
      return false
    },
    shippingAddress () {
      if (!this.order || _.isEmpty(this.order)) return {}
      if (this.order && this.order.shipping && this.order.shipping.last) {
        return this.order.shipping
      } else if (this.order.groupId) {
        // multi-location
        return _.map(
          o => ({
            location: o.shipping,
            items: _.keys(o.items).length,
            units: _.sum(_.values(o.items))
          }),
          _.values(this.order.orders)
        )
      } else {
        return _.assign(
          {},
          this.locations.find(l => l._id === this.user.profile.shipping)
        )
      }
    },
    billingAddress () {
      if (!this.order || _.isEmpty(this.order)) return {}
      if (this.order && this.order.billing.last) {
        return this.order.billing
      } else {
        return _.assign(
          {},
          this.locations.find(l => l._id === this.user.profile.billing)
        )
      }
    },
    bizLocations () {
      if (this.user.authenticated) {
        return _.filter(
          l => ~this.user.profile.location.indexOf(l._id),
          this.locations
        )
      }
      return []
    },
    userLocations () {
      if (this.user.authenticated) {
        return this.user.profile.addresses
      }
      return []
    },
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/checkout/CustomerInfo.css"></style>
